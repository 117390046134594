import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Dropdown, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  AsertoNavBar,
  ReactSelectElement,
  Select,
  SelectOption,
  theme,
  UndecoratedLink,
  useDispatchContext,
  useIdentity,
} from '@aserto/console-common'

import { useAccountInvites } from '../../api/members'
import AsertoLogo from '../../assets/aserto-horizontal-white-text.svg'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { AccountTenantInvite } from '../../types/local/tenant'
import { isInviteAcceptable } from '../../utils/invites'
import ApiDocsDropdownItem from './ApiDocsDropdownItem'
import { DropdownMenu, ImgBadge, StyledDropdown, TenantInputContainer } from './styles'

export const NavBar = () => {
  const dispatch = useDispatchContext()
  const navigate = useNavigate()
  const { logout, user } = useIdentity()
  const tenantSelectRef = useRef<ReactSelectElement>(null)
  const { account, setTenantId, tenant } = useProfile()
  const { data: accountInvitesData } = useAccountInvites()
  const acceptableInvites = useMemo(() => {
    const invites: Array<AccountTenantInvite> = accountInvitesData?.results ?? []
    return invites.filter(isInviteAcceptable)
  }, [accountInvitesData?.results])

  const checkActive = (_match: unknown, location: { pathname: string }) => {
    if (!location) {
      return false
    }
    const { pathname } = location
    const route = window.location.pathname
    if (!route) {
      return false
    }
    if (route === '/' && pathname === '/ui/policies') {
      return true
    }
    return route === pathname || route.startsWith(`${pathname}/`)
  }
  const customLink = (to: string, children: React.ReactNode) => {
    return (
      <LinkContainer to={to}>
        <Nav.Link active={checkActive(null, { pathname: to })}>{children}</Nav.Link>
      </LinkContainer>
    )
  }
  const customRootLink = (to: string, active: boolean, children: React.ReactNode) => {
    const { pathname } = window.location
    if (active && pathname === '/') {
      return <Nav.Link active={checkActive(null, { pathname: to })}>{children}</Nav.Link>
    }
    return customLink(to, children)
  }
  const PoliciesLink = () => {
    return customRootLink('/ui/policies', true, 'Policies')
  }

  const DirectoryLink = () => {
    return customLink('/ui/directory', 'Directory')
  }

  const ConnectionsLink = () => {
    return customLink('/ui/connections', 'Connections')
  }

  const ImagesLink = () => {
    return customLink('/ui/images', 'Images')
  }

  const AuthorizersLink = () => {
    return customLink('/ui/authorizers', 'Authorizers')
  }

  const AccountSettingsLink = () => {
    return customLink('/ui/account-settings', 'Account Settings')
  }
  const tenants: Array<SelectOption> = useMemo(() => {
    return (
      account?.tenants
        ?.sort((tenant, otherTenant) => {
          if (tenant.personal && !otherTenant.personal) {
            return -1
          }

          if (!tenant.personal && otherTenant.personal) {
            return 1
          }

          return tenant.name!.localeCompare(otherTenant.name!)
        })
        .map((tenant) => ({
          value: tenant.id!,
          label: tenant.personal ? `${tenant.name!} (account)` : tenant.name!,
        })) ?? []
    )
  }, [account?.tenants])

  const options = useMemo(() => {
    if (account?.tenants?.length === 1) {
      return [
        {
          label: '',
          options: [
            {
              label: 'Create an organization',
              value: 'create',
              shouldStopPropagation: true,
              onClick: () => {
                dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
              },
            },
          ],
        },
      ]
    }
    return [
      {
        options: [
          {
            label: 'Create an organization',
            value: 'create',
            shouldStopPropagation: true,
            onClick: () => {
              dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
            },
          },
          ...(!tenant?.personal
            ? [
                {
                  label: 'Manage organization',
                  value: 'manage',
                  shouldStopPropagation: true,
                  onClick: () => navigate('/ui/manage-tenant'),
                },
              ]
            : []),
        ],
      },
      {
        label: 'Organizations',
        options: tenants,
      },
    ]
  }, [account?.tenants?.length, dispatch, navigate, tenant?.personal, tenants])

  useEffect(() => {
    if (tenant?.id) {
      tenantSelectRef?.current?.setValue(
        tenants.find((option: SelectOption) => option?.value === tenant?.id)!,
        'select-option'
      )
    }
  }, [tenant, tenants])

  const onChangeTenantId = useCallback(
    (data: SelectOption | null) => {
      if (data?.value && data?.value !== tenant?.id) {
        setTenantId(String(data.value))
        Object.keys(localStorage)
          .filter((key) => key.includes('graphiql'))
          .forEach((key) => localStorage.removeItem(key))
      }
    },
    [setTenantId, tenant?.id]
  )

  return (
    <AsertoNavBar
      expand="xl"
      logo={
        <a href="/">
          <img src={AsertoLogo} />
        </a>
      }
      showBrandSeparator={true}
      uncollapsableContent={
        <TenantInputContainer>
          <Select
            ref={tenantSelectRef}
            defaultValue={tenants?.find((option: SelectOption) => option?.value === tenant?.id)}
            formatGroupLabel={(a) => {
              return !!a?.label ? (
                <div
                  style={{
                    alignItems: 'center',
                    borderBottom: '1px #414141 solid',
                    borderTop: '1px #414141 solid',
                    color: theme.grey100,
                    display: 'flex',
                    fontSize: 14,
                    fontWeight: 'bold',
                    height: '100%',
                    minHeight: '36px',
                  }}
                >
                  {a.label}
                </div>
              ) : undefined
            }}
            isSearchable={false}
            modifyCustomStyle={(style) => {
              if (!style.groupHeading) {
                return {}
              }
              return {
                ...style,
                groupHeading: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: '#2a2a2a',
                    margin: 0,
                  }
                },
              }
            }}
            options={options}
            placeholder="Select tenant"
            style={{
              width: '100%',
              height: 35,
            }}
            onChange={onChangeTenantId}
          />
        </TenantInputContainer>
      }
    >
      <Nav as="ul" className="mr-auto d-ms">
        <>
          <Nav.Item as="li">
            <PoliciesLink />
          </Nav.Item>
          <Nav.Item as="li">
            <ImagesLink />
          </Nav.Item>
          <Nav.Item as="li">
            <DirectoryLink />
          </Nav.Item>
          <Nav.Item as="li">
            <AuthorizersLink />
          </Nav.Item>
          <Nav.Item as="li">
            <ConnectionsLink />
          </Nav.Item>
        </>
        <Nav.Item as="li" className="d-xl-none d-md-block">
          <AccountSettingsLink />
        </Nav.Item>
        <Nav.Item as="li" className="d-xl-none d-md-block">
          <Nav.Link onClick={logout}>Sign out</Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className="d-grid gap-2 d-none d-xl-block ms-auto">
        <StyledDropdown>
          <Dropdown.Toggle as={Nav.Link} className="toggle-profile position-relative">
            {acceptableInvites.length > 0 && <ImgBadge />}
            <img
              alt="Profile"
              className="nav-user-profile rounded-circle"
              height="41"
              src={user?.picture}
              width="41"
            />
          </Dropdown.Toggle>
          <DropdownMenu className="dropdown-menu-lg-end">
            <Dropdown.Header>{user?.name}</Dropdown.Header>
            <UndecoratedLink to="/ui/account-settings">
              <Dropdown.Item as="li">
                <i className="fa fa-user mr-3 mx-1" />
                &nbsp; &nbsp; Account settings
              </Dropdown.Item>
            </UndecoratedLink>

            {tenant !== null && <ApiDocsDropdownItem tenant={tenant} />}
            <Dropdown.Item id="qsLogoutBtn" onClick={logout}>
              <i className="fa fa-power-off mr-3 mx-1" />
              &nbsp; &nbsp; Sign out
            </Dropdown.Item>
          </DropdownMenu>
        </StyledDropdown>
      </Nav>
    </AsertoNavBar>
  )
}

// This is the project's abstraction around the history APIs, so disabling protection

import { createBrowserHistory, History } from 'history'
// This is the project's abstraction around the history APIs, so disabling protection
import React, { useContext } from 'react'
// This is the project's abstraction around the history APIs, so disabling protection

export { createMemoryHistory } from 'history'

type HistoryProviderContextProps = {
  history: History
}

const historyContext = React.createContext<HistoryProviderContextProps>({
  history: createBrowserHistory(),
})

export const useHistory = () => {
  return useContext(historyContext).history
}

import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  SpinnerToggle,
  useDirectoryV3ManifestSet,
  useShowError,
} from '@aserto/console-common'

import { useConnections, useGetConnections, useUpdateConnection } from '../../../api/connections'
import { useGetManifest, useListOnboardingTemplates } from '../../../api/onboarding'
import { useApprConnectionAndOrganization } from '../../../api/registry'
import { useListPolicy } from '../../../api/v2/policy'
import { useGetPolicyStates, useSetPolicyState } from '../../../api/v2/policyState'
import ApiAuthIcon from '../../../assets/onboarding/api-auth.svg'
import DefaultTemplateIcon from '../../../assets/onboarding/default.svg'
import Gdrive from '../../../assets/onboarding/gdrive.svg'
import Github from '../../../assets/onboarding/github.svg'
import MultiTenantIcon from '../../../assets/onboarding/multi-tenant.svg'
import PeoplefinderIcon from '../../../assets/onboarding/peoplefinder.svg'
import SimpleRbacIcon from '../../../assets/onboarding/simple-rbac.svg'
import Slack from '../../../assets/onboarding/slack.svg'
import TodoIcon from '../../../assets/onboarding/todo.svg'
import { getDefaultRepoNameFromTemplate } from '../../../lib/repository'
import { useOnboardingContext } from '../../../services/OnboardingContextProvider'
import { OnboardingTemplate } from '../../../types/local/consoleBackend'
import { ProviderId } from '../../../types/local/hardcodedBackendConstants'
import { useEnsureSimpleConnection } from '../../../utils/connections'
import {
  AllContent,
  ButtonContainer,
  CenteredContent,
  IconDiv,
  InfoCard,
  ModalDescription,
  RightContent,
  TemplateCard,
  TemplatesDiv,
} from '../styles'
import DeleteManifestModal from './DeleteManifestModal'

const iconsMapping: { [key: string]: string } = {
  'simple-rbac': SimpleRbacIcon,
  todo: TodoIcon,
  peoplefinder: PeoplefinderIcon,
  slack: Slack,
  github: Github,
  gdrive: Gdrive,
  'api-auth': ApiAuthIcon,
  'multi-tenant': MultiTenantIcon,
}
type ApplyTemplateProp = {
  deleteAssets?: (selectedTemplate?: OnboardingTemplate) => Promise<void>
  onSuccess?: () => void
}

const ApplyTemplate: React.FC<ApplyTemplateProp> = ({ deleteAssets, onSuccess }) => {
  const { selectedTemplate, setSelectedTemplate, activeStep, setActiveStep } =
    useOnboardingContext()
  const showError = useShowError()
  const { data: templates } = useListOnboardingTemplates({
    onError: (error) => {
      showError(error)
    },
  })
  const { mutateAsync: setManifest, isLoading: isLoadingSetManifest } = useDirectoryV3ManifestSet({
    mutation: {
      onError: (error) => {
        setIsLoading(false)
        showError(error)
      },
    },
  })
  const endToEndSamples = templates?.results?.filter((t) =>
    t.content?.short_description?.includes('end-to-end')
  )
  const authzTemplates = templates?.results?.filter(
    (t) => !t.content?.short_description?.includes('end-to-end')
  )
  const isE2ESample = selectedTemplate?.content?.short_description?.includes('end-to-end')
  const defaultTemplate = templates?.results?.[0]
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (defaultTemplate && !selectedTemplate) {
      setSelectedTemplate && setSelectedTemplate(defaultTemplate)
    }
  }, [defaultTemplate, selectedTemplate, setSelectedTemplate])

  const { data: allPoliciesData } = useListPolicy({
    name_starts_with: selectedTemplate?.policy_instance?.name,
  })

  const buildPolicyImage = (repo: string, org: string, tag: string) => {
    return `ghcr.io/${org}/${repo}:${tag}`
  }

  const matchedPolicies = useMemo(() => {
    if (!allPoliciesData || !allPoliciesData.results) {
      return []
    }
    return allPoliciesData.results.map(({ id }) => id!)
  }, [allPoliciesData])

  const policyStatesData = useGetPolicyStates(matchedPolicies)

  const policyState = useMemo(() => {
    return policyStatesData.map((state) => {
      return state.data?.state
    })
  }, [policyStatesData])

  const isSamePolicyExisting = () => {
    return policyState.some((policyData) => {
      if (!!policyData) {
        const policyImage = buildPolicyImage(
          policyData.repository?.repo || '',
          policyData.repository?.org || '',
          policyData.instance?.[0].tag || ''
        )
        return policyImage === selectedTemplate?.policy_instance?.image
      }
    })
  }

  const { data: manifestData } = useGetManifest(selectedTemplate?.manifest || '')
  const ensureSimpleConnection = useEnsureSimpleConnection()
  const { connectionId: registryConnectionId, organization: registryOrg } =
    useApprConnectionAndOrganization()
  const { mutateAsync: setPolicyState } = useSetPolicyState({
    onMutate: () => setIsLoading(true),
    onError: (error) => {
      setIsLoading(false)
      showError(error)
    },
  })

  const policyName = useMemo(() => {
    return getDefaultRepoNameFromTemplate(
      selectedTemplate?.policy_instance?.name || '',
      allPoliciesData?.results?.map((p) => p.name!) || []
    )
  }, [allPoliciesData?.results, selectedTemplate?.policy_instance?.name])

  // returns ['repo', 'tag']
  const splitImage = (image: string) => {
    if (image === '') {
      return ['', '']
    }

    const parts = image.split('/')
    const imageParts = parts[2].split(':')
    return [imageParts[0], imageParts[1]]
  }

  const createAuthorizerInstance = useMemo(() => {
    if (!selectedTemplate?.policy_instance) {
      return undefined
    }

    return async () => {
      const [repo, tag] = splitImage(selectedTemplate.policy_instance?.image || '')
      await setPolicyState({
        policy: {
          name: policyName,
        },
        repository: {
          connection_id: registryConnectionId || '',
          org: registryOrg,
          repo: repo,
        },
        instance: [
          {
            decision_logging: false,
            label: policyName,
            tag: tag,
            instance_type: 'INSTANCE_TYPE_HOSTED',
          },
        ],
      })
    }
  }, [
    registryConnectionId,
    registryOrg,
    selectedTemplate?.policy_instance,
    setPolicyState,
    policyName,
  ])

  const { refetch: refetchIdp } = useConnections({ kind: 'PROVIDER_KIND_IDP' }, false)
  const { refetch: refetchData } = useConnections({ kind: 'PROVIDER_KIND_DATA' }, false)

  const { data: connectionsdata } = useConnections({
    kind: 'PROVIDER_KIND_IDP',
  })
  const existingConnectionsIds = useMemo(() => {
    const newConnectionProvider = selectedTemplate?.connection?.provider_id
    let ignoreProviderId = ''
    if (newConnectionProvider && newConnectionProvider === ProviderId.IDPAcmeCorp) {
      ignoreProviderId = ProviderId.IDPCitadel
    }

    if (newConnectionProvider && newConnectionProvider === ProviderId.IDPCitadel) {
      ignoreProviderId = ProviderId.IDPAcmeCorp
    }
    const connectionsData = (connectionsdata?.results || []).filter(
      (con) => con.provider_id !== ignoreProviderId
    )
    return connectionsData.map(({ id }) => id!) || []
  }, [connectionsdata?.results, selectedTemplate?.connection?.provider_id])

  const existingConnectionData = useGetConnections(existingConnectionsIds)
  const existingConnections = useMemo(() => {
    return existingConnectionData.map((data) => {
      return data.data?.result
    })
  }, [existingConnectionData])

  const applyTemplate = async () => {
    // set manifest
    setIsLoading(true)
    if (!!manifestData) {
      await setManifest({ data: manifestData })
    }

    // create idp connection
    ensureSimpleConnection(selectedTemplate?.connection?.provider_id || '')
    // set idp connections to be synced
    const data = await refetchIdp()
    data.data?.results?.forEach(async (connection) => {
      const existingConnection = existingConnections.filter((con) => con?.id === connection.id)?.[0]
      const finalConnection = existingConnection || connection
      await syncConnection({ connectionId: finalConnection.id!, ...finalConnection })
    })

    // create data connection
    if (!!selectedTemplate?.data_provider?.provider_id) {
      ensureSimpleConnection(selectedTemplate?.data_provider?.provider_id || '')
      const data = await refetchData()
      data.data?.results?.forEach(async (connection) => {
        await syncConnection({ connectionId: connection.id!, ...connection })
      })
    }

    // set policy instace
    if (!isSamePolicyExisting()) {
      await createAuthorizerInstance?.()
    }

    // redirect or continue
    !onSuccess ? setActiveStep(activeStep + 1) : onSuccess()
  }
  const { mutateAsync: syncConnection } = useUpdateConnection({
    onMutate: () => {
      setIsLoading(true)
    },
  })

  return (
    <>
      <DeleteManifestModal
        show={showDeleteModal}
        onClickRemove={async () => {
          if (!!deleteAssets) {
            setShowDeleteModal(false)
            await deleteAssets(selectedTemplate)
          }
          await applyTemplate()
        }}
        onHide={() => setShowDeleteModal(false)}
      />
      <AllContent>
        <CenteredContent>
          <ModalDescription>
            An end-to-end sample includes a sample application with an authorization policy and
            sample data. It's a good place to start if you're new to authorization.
          </ModalDescription>
          <TemplatesDiv>
            {endToEndSamples?.map((t, i) => (
              <TemplateCard
                key={i}
                $active={selectedTemplate?.id === t.id}
                onClick={() => setSelectedTemplate && setSelectedTemplate(t)}
              >
                <>
                  <IconDiv>
                    <img
                      alt={t.id}
                      className="icon"
                      src={iconsMapping[t.id!] || DefaultTemplateIcon}
                    />
                  </IconDiv>
                  <p className="template-title">{t.content?.title}</p>
                  <p className="template-desc">{`${t.content?.short_description}`}</p>
                </>
              </TemplateCard>
            ))}
          </TemplatesDiv>
          <ButtonContainer>
            <Button
              disabled={!selectedTemplate || !createAuthorizerInstance}
              variant="primary"
              onClick={() => {
                deleteAssets ? setShowDeleteModal(true) : applyTemplate()
              }}
            >
              Apply template
            </Button>
          </ButtonContainer>
          <br />
          <ModalDescription>
            An authorization template is a great starting point if you already know the
            authorization model you'd like to implement. Don't worry, you'll be able to change or
            customize it later!
          </ModalDescription>
          <TemplatesDiv>
            {authzTemplates?.map((t, i) => (
              <TemplateCard
                key={i}
                $active={selectedTemplate?.id === t.id}
                onClick={() => setSelectedTemplate && setSelectedTemplate(t)}
              >
                <>
                  <IconDiv>
                    <img
                      alt={t.id}
                      className="icon"
                      src={iconsMapping[t.id!] || DefaultTemplateIcon}
                    />
                  </IconDiv>
                  <p className="template-title">{t.content?.title}</p>
                  <p className="template-desc">{`${t.content?.short_description}`}</p>
                </>
              </TemplateCard>
            ))}
          </TemplatesDiv>
          <ButtonContainer>
            <Button
              disabled={!selectedTemplate || !createAuthorizerInstance}
              variant="primary"
              onClick={() => {
                deleteAssets ? setShowDeleteModal(true) : applyTemplate()
              }}
            >
              Apply template
            </Button>
          </ButtonContainer>
        </CenteredContent>
        <RightContent>
          {!!selectedTemplate && (
            <InfoCard $isE2ESample={isE2ESample}>
              <p className="info-title">More about {selectedTemplate.content?.title}</p>
              <p className="info-desc">{selectedTemplate.content?.description}</p>
              <p className="info-link">
                <a
                  href={selectedTemplate.content?.docs_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Learn More
                </a>
              </p>
            </InfoCard>
          )}
        </RightContent>
      </AllContent>
      <SpinnerToggle show={isLoading || isLoadingSetManifest} />
    </>
  )
}

export default ApplyTemplate

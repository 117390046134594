import React, { PropsWithChildren } from 'react'
import {
  BackendServicesAuthProvider,
  InternalConfigProvider,
  useIdentity,
} from '@aserto/console-common'

import { useAuthorizerConnection, useCentralDirectoryConnection } from '../../api/connections'
import { useConfig } from '../ConfigProvider'
import { useProfile } from '../ProfileAndQueryClientProvider'

const ConsoleCommonProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const config = useConfig()
  const { authorizerServiceUrl, directoryServiceUrl } = config
  const { tenant } = useProfile()
  const { getAccessToken } = useIdentity()
  const { data: directoryConnection } = useCentralDirectoryConnection(!!tenant)
  const directoryApiKey = directoryConnection?.result?.config?.api_key_read as string
  const { data: authorizerConnection } = useAuthorizerConnection()
  const authorizerApiKey = authorizerConnection?.result?.config?.api_key as string

  return !config ? null : (
    <InternalConfigProvider
      config={{
        authorizerServiceUrl,
        authorizerApiKey,
        directoryServiceUrl,
        directoryApiKey,
        directoryModelServiceUrl: directoryServiceUrl,
        directoryReaderServiceUrl: directoryServiceUrl,
        directoryWriterServiceUrl: directoryServiceUrl,
      }}
    >
      <BackendServicesAuthProvider
        backendServicesAuth={{
          getAuthorizerToken: getAccessToken,
          getDirectoryToken: getAccessToken,
        }}
      >
        {children}
      </BackendServicesAuthProvider>
    </InternalConfigProvider>
  )
}

export default ConsoleCommonProvider

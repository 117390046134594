import React, { PropsWithChildren, useEffect } from 'react'
import { InformationalError, useShowError } from '@aserto/console-common'

import { useInfo } from '../../api/info'

const ServiceInfoProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isError } = useInfo()
  const showError = useShowError()

  useEffect(() => {
    if (isError) {
      showError(
        new InformationalError(
          'This may be a temporary error. Please try reloading this page. If the error persists, contact support@aserto.com.',
          'Looks like we are having issues with our services :('
        )
      )
    }
  }, [isError, showError])

  return isError ? null : <>{children}</>
}

export default ServiceInfoProvider

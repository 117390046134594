import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Button, CardModal, Select, SelectOption, useIdentity } from '@aserto/console-common'
import { SpinnerToggle } from '@aserto/console-common'

import { useProviders } from '../../../api/connections'
import useIsConnectionNameAvailable from '../../../lib/availability/useIsConnectionNameAvailable'
import {
  NON_SYSTEM_CONNECTION_FILTER_OPTIONS,
  NonSystemConnectionFilter,
  NonSystemConnectionFilterDefinitions,
} from '../../../lib/connection'
import { getProviderById } from '../../../lib/connection'
import ConnectionNameValidator from '../../../lib/validation/ConnectionNameValidator'
import { useBasicAuthConnectionMaker } from '../../../services/connectionMakers/basicAuthConnectionMaker'
import { useOAuthConnectionMaker } from '../../../services/connectionMakers/oauthConnectionMaker'
import { Provider } from '../../../types/local/tenant'
import { DynamicConnectionForm, DynamicConnectionFormField } from '../DynamicConnectionForm'
import { isAddConnectionFormValid, shouldValidateConnectionFormBasedOnConfig } from './utils'

const ContentContainer = styled.div`
  padding: 20px;
  width: 100%;
`

const FormContainer = styled.div`
  margin-top: 18px;
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 12px auto;
`

const ButtonsContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;
  display: flex;
  margin-top: 20px;
  button:first-of-type {
    margin-right: 10px;
  }
`
type AddConnectionModalProps = {
  oAuthRedirectUrl: string
  providerFilter?: (provider: Provider) => boolean
  providerKind?: NonSystemConnectionFilter
  show: boolean
  onChange?: (connectionId: string) => void
  onHide: () => void
  onSuccess?: () => void
}

export type AddConnectionModalFormContent = {
  description: string
  name: string
  providerId: string
  [s: string]: string | boolean
}

const formInitialState = {
  description: '',
  name: '',
  providerId: '',
}

const AddConnectionModal: React.FC<AddConnectionModalProps> = ({
  oAuthRedirectUrl,
  onChange,
  onHide,
  providerFilter,
  providerKind,
  show,
  onSuccess,
}) => {
  const useIsNameAvailable = useIsConnectionNameAvailable()
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(
    NON_SYSTEM_CONNECTION_FILTER_OPTIONS.find(
      (o) => o.value === (providerKind ?? 'ALL_CONNECTIONS')
    )
  )

  const providerType = useMemo(
    () =>
      NonSystemConnectionFilterDefinitions[
        (selectedOption?.value as NonSystemConnectionFilter) ?? 'ALL_CONNECTIONS'
      ]?.providerKind,
    [selectedOption?.value]
  )
  const [formContent, setFormContent] = useState<AddConnectionModalFormContent>(formInitialState)
  const { data: providersData } = useProviders(providerType)

  const providers = useMemo(
    () => (providersData?.results || []).filter((p) => p.kind !== 'PROVIDER_KIND_DATA'),
    [providersData?.results]
  )
  const { getAccessToken } = useIdentity()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setFormContent(formInitialState)
    }
  }, [show])

  const providersOptions = useMemo(() => {
    return providers
      .filter(providerFilter || (() => true))
      .map((provider: Provider) => {
        return { label: provider.description!, value: provider.id! }
      })
      .sort((p1, p2) => p1.label.localeCompare(p2.label))
  }, [providerFilter, providers])
  const setFormContentForKey = useCallback((key: string, value: string | boolean) => {
    return setFormContent((form) => {
      return {
        ...form,
        [key]: value,
      }
    })
  }, [])

  const onClickHideButton = () => {
    onHide()
    setFormContent(formInitialState)
    setSelectedOption(undefined)
  }

  const selectedProvider = useMemo(() => {
    return providers.find((provider) => {
      return provider.id === formContent.providerId
    })
  }, [providers, formContent.providerId])

  const isNameValid = useMemo(() => {
    return ConnectionNameValidator.isValid(formContent.name)
  }, [formContent.name])

  const validateConnectionForm = useCallback(
    (connectionFormContent: AddConnectionModalFormContent, provider: Provider): boolean => {
      if (selectedProvider === undefined || !isNameValid) {
        return false
      }

      if (
        shouldValidateConnectionFormBasedOnConfig(provider) &&
        !isAddConnectionFormValid(connectionFormContent, selectedProvider.config!)
      ) {
        return false
      }

      return true
    },
    [isNameValid, selectedProvider]
  )

  const isFormValid = useMemo(() => {
    return selectedProvider?.config ? validateConnectionForm(formContent, selectedProvider) : false
  }, [selectedProvider, formContent, validateConnectionForm])

  const defaultConnectionFields: DynamicConnectionFormField[] = useMemo(
    () => [
      {
        name: 'name',
        label: 'Name',
        info: ConnectionNameValidator.ruleText,
        isValid: isNameValid,
        useIsAvailable: useIsNameAvailable,
      },
      { name: 'description', label: 'Display name (optional)' },
    ],
    [isNameValid, useIsNameAvailable]
  )

  const helpText = selectedProvider?.display_attributes?.['help_text']
  const helpUrl = selectedProvider?.display_attributes?.['help_url']

  const onClickConnect = async (addConnectionFormContent: AddConnectionModalFormContent) => {
    onHide()
    setFormContent(formInitialState)
    setSelectedOption(undefined)
    const token = await getAccessToken()
    const providers = providersData?.results || []
    const selectedProvider = getProviderById(providers, addConnectionFormContent.providerId)
    if (selectedProvider.connection_type === 'CONNECTION_TYPE_OAUTH') {
      setIsLoading(true)
      await makeOAuthConnection({
        connectionInfo: addConnectionFormContent,
        provider: selectedProvider,
        token,
        redirectUrlPath: oAuthRedirectUrl,
      })
    } else {
      makeBasicAuthConnection({ content: addConnectionFormContent, selectedProvider })
    }
  }

  const makeBasicAuthConnection = useBasicAuthConnectionMaker({
    setIsLoading,
    setCreatedConnectionId: onChange,
    onSuccess,
  })

  useEffect(() => {
    if (providersOptions.length === 1) {
      setFormContentForKey('providerId', String(providersOptions[0].value))
    }
  }, [providersOptions, setFormContentForKey])

  const makeOAuthConnection = useOAuthConnectionMaker()

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClickHideButton()
    }
  }

  return (
    <>
      <SpinnerToggle show={isLoading} />
      <CardModal cardHeight="100%" show={show} title="Add a connection" onHide={onClickHideButton}>
        <ContentContainer
          className="add-a-connection-modal-content"
          tabIndex={-1}
          onKeyDown={onKeyDown}
        >
          <div>
            Select a provider, enter your credentials, and click the Add connection button. After
            successful verification, the connection will be added.
          </div>
          <FormContainer>
            <FieldContainer>
              {!providerKind && (
                <Select
                  autoFocus
                  className="provider-type-select"
                  label="Type"
                  name="provider-type-select"
                  options={NON_SYSTEM_CONNECTION_FILTER_OPTIONS}
                  value={selectedOption}
                  onChange={(o) => setSelectedOption(o ?? undefined)}
                />
              )}
              <Select
                autoFocus={!!providerKind}
                className="provider-select"
                label="Provider"
                name="provider-select"
                options={providersOptions}
                value={
                  providersOptions.length === 1
                    ? providersOptions[0]
                    : providersOptions.find((provider) => provider.value === formContent.providerId)
                }
                onChange={(option) => setFormContentForKey('providerId', String(option!.value))}
              />
            </FieldContainer>

            <DynamicConnectionForm
              configElements={selectedProvider?.config ?? null}
              defaultFields={defaultConnectionFields}
              helpText={helpText}
              helpUrl={helpUrl}
              onChange={setFormContentForKey}
            />
          </FormContainer>
          <ButtonsContainer>
            <Button data-testid="cancel-btn" variant="secondary" onClick={onClickHideButton}>
              Cancel
            </Button>
            <Button
              data-testid="add-connection-modal-btn"
              disabled={!selectedProvider || !isFormValid}
              id="add-connection"
              type="submit"
              onClick={() => onClickConnect(formContent)}
            >
              Add connection
            </Button>
          </ButtonsContainer>
        </ContentContainer>
      </CardModal>
    </>
  )
}

export default AddConnectionModal

import { useMutation, useQuery, useQueryClient } from 'react-query'

import { useConsoleBackendClient } from '../clients/ConsoleBackendClient'
import {
  GetAccountResponse,
  ResendVerificationEmailRequest,
  ResendVerificationEmailResponse,
  SyncUserResponse,
} from '../types/local/consoleBackend'
import {
  QueryKeys,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../types/local/general'

export const useDs0Account = () => {
  const { get } = useConsoleBackendClient()
  return useQuery(
    [QueryKeys.Account],
    () => get<GetAccountResponse>({ path: 'ds0/account' }),
    reactQueryDefaultOptions
  )
}

export const useResendVerificationEmail = (
  reactQueryHooks: ReactQueryMutationHooks<
    ResendVerificationEmailResponse,
    ResendVerificationEmailRequest
  > = {}
) => {
  const mutationOptions = useMutationOptions(reactQueryHooks)
  const { post } = useConsoleBackendClient()

  return useMutation(
    [],
    (body) =>
      post({
        body,
        path: `resend-verification-email`,
      }),
    {
      ...mutationOptions,
    }
  )
}

export const useSyncUser = (
  reactQueryHooks: ReactQueryMutationHooks<SyncUserResponse, unknown> = {}
) => {
  const mutationOptions = useMutationOptions(reactQueryHooks)
  const { post } = useConsoleBackendClient()
  const queryClient = useQueryClient()

  return useMutation(
    [],
    (body) =>
      post({
        body,
        path: `sync-user`,
      }),
    {
      ...mutationOptions,
      onSuccess: (data, context) => {
        queryClient.refetchQueries([QueryKeys.Account])
        mutationOptions?.onSuccess?.(data, context)
      },
    }
  )
}

import React, { PropsWithChildren, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GenericIdentityProvider, useShowError } from '@aserto/console-common'
// This is the project's abstraction around the auth0 APIs, so disabling protection
import { AppState, useAuth0, User as Auth0User } from '@auth0/auth0-react'

import DisabledAccount from '../../views/DisabledAccount'
import { useEnvConfig } from '../EnvConfigProvider'

export type User = Auth0User

type IdentityProviderProps = PropsWithChildren

type IdentityProviderContextProps =
  | {
      getAccessToken: () => Promise<string>
      logout: () => void
      user: User | undefined
    }
  | null
  | undefined

const identityProviderContext = React.createContext<IdentityProviderContextProps>(undefined)

const IdentityProvider: React.FC<IdentityProviderProps> = ({ children }) => {
  const {
    loginWithRedirect,
    isLoading,
    isAuthenticated,
    error: auth0Error,
    getAccessTokenSilently,
    logout,
    user,
  } = useAuth0()

  const { websiteUrl } = useEnvConfig()

  const wrappedLogout = useCallback(() => {
    logout({
      returnTo: websiteUrl,
    })
    sessionStorage.clear()
    localStorage.clear()
  }, [logout, websiteUrl])

  const getAccessTokenBearer = useCallback(async () => {
    return `Bearer ${await getAccessTokenSilently()}`
  }, [getAccessTokenSilently])
  const showError = useShowError()
  useEffect(() => {
    // require a logged-in user
    if (!isAuthenticated && !isLoading && !auth0Error) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
        redirectUri: window.location.origin,
      })
    }
  }, [isLoading, isAuthenticated, auth0Error, loginWithRedirect])

  if (!!auth0Error) {
    if (auth0Error.message.includes('user is blocked')) {
      return <DisabledAccount></DisabledAccount>
    } else if (auth0Error.message.includes('Invalid state')) {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
      })
    } else {
      showError(auth0Error)
    }
  }

  return isAuthenticated ? (
    <identityProviderContext.Provider value={null}>
      <GenericIdentityProvider
        identity={{
          user: user,
          getAccessToken: getAccessTokenBearer,
          logout: wrappedLogout,
        }}
      >
        {children}
      </GenericIdentityProvider>
    </identityProviderContext.Provider>
  ) : null
}

export type MemoryIdentityProviderProps = {
  getAccessToken?: () => Promise<string>
  logout?: () => void
  user?: Auth0User
}

export const MemoryIdentityProvider: React.FC<PropsWithChildren<MemoryIdentityProviderProps>> = ({
  children,
  getAccessToken,
  logout,
  user,
}) => {
  return (
    <identityProviderContext.Provider
      value={{
        getAccessToken: getAccessToken || (() => Promise.resolve('Bearer ')),
        logout: logout || (() => {}),
        user: user || undefined,
      }}
    >
      <GenericIdentityProvider
        identity={{
          user: user || undefined,
          getAccessToken: getAccessToken || (() => Promise.resolve('Bearer ')),
          logout: logout || (() => {}),
        }}
      >
        {children}
      </GenericIdentityProvider>
    </identityProviderContext.Provider>
  )
}

export const useOnRedirectCallback = () => {
  const navigate = useNavigate()

  return useCallback(
    (appState?: AppState | undefined) => {
      navigate(appState?.returnTo || window.location.pathname)
    },
    [navigate]
  )
}

export default IdentityProvider
